.footer{
    width: 100%;
    background-color: #ffffff;
    background-position: center;
    background-size: cover;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.main-footer{
    display: flex;
    padding: 20px 20px;
    /* align-items: center; */
    justify-content: space-between;
}

.main-footer .box1{
    width: 100%;
    display: flex;
    gap: 30px;
    align-items: center;
    justify-content: center;
}

.main-footer .box1 li{
    list-style-type: none;
    color: #AD49E1;
    font-size: 16px;
    padding: 10px 0;
    font-weight: bold;
}

.main-footer .box1 li a{
    color: #AD49E1;
    text-decoration: none;
}


.bottom-footer{
    padding: 20px 20px;
    background-color: #AD49E1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bottom-footer h3{
    font-size: 16px;
    color: #ffffff;
}


@media (max-width: 1200px) {}
@media (max-width: 768px) {
    .main-footer .box1{
        flex-direction: column;
        gap: 20px;
    }
}
@media (max-width: 480px) {
    .main-footer .box1{
        flex-direction: column;
        gap: 10px;
    }
}