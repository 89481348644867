/* Preloader.css */
.preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .preloader-logo {
    width: 100px; /* Adjust the size of your logo */
    height: auto;
    animation: blink 1s infinite; /* Blink animation */
    border-radius: 50%;
  }
  
  @keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
  }
  