.home-container{
    /* height: 90vh; */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 20px 120px;
}

.home-container .homebox1{
    width: 50%;
    display: flex;
    align-items: start;
    justify-content: start;
    flex-direction: column;
}

.home-container .homebox1 h1{
    color: #AD49E1;
    font-size: 45px;
    margin: 0;
    margin-left: -5px;
}

.home-container .homebox1 h2{
    color: #AD49E1;
    font-size: 22px;
    padding-bottom: 10px;
}

.home-container .homebox1 img{
    width: 100px;
}

.home-container .homebox1 .line{
    width: 80px;
    padding-bottom: 30px;
    padding-top: 20px;
}

.home-container .homebox1 button{
    padding: 15px 30px;
    background-color: #AD49E1;
    font-size: 16px;
    border-radius: 50px;
    color: #ffffff;;
    border: none;
    font-weight: bold;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    border: 2px solid #AD49E1;
    cursor: pointer;
}


.home-container .homebox1 p{
    color: #AD49E1;
    font-size: 16px;
    width: 100%;
    padding: 20px 0;
    font-weight: 600;
    line-height: 25px;
}


.home-container .homebox2{
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: start;
    padding-top: 60px;
    padding-bottom: 60px;
}

.home-container .homebox2 img{
    width: 70%;
}

.floating {  
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

@keyframes floating {
    from { transform: translate(0,  0px); }
    65%  { transform: translate(0, 15px); }
    to   { transform: translate(0, -0px); }    
}

.homebox3{
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.homebox3 .socials{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.homebox3 .socials i{
    font-size: 16px;
    padding: 10px;
    background-color: #AD49E1;
    color: #ffffff;
    border-radius: 3px;
}



.about{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #AD49E1;
    padding: 60px 120px;
    gap: 20px;
}

.about h2{
    color: #ffffff;
    font-size: 30px;
    text-align: center;
}

.about .line{
    width: 100px;
}

.about p{
    font-size: 15px;
    font-weight: 500;
    color: #ffffff;
    font-weight: 400;
    text-align: center;
    padding-top: 20px;
    width: 70%;
}

.about .skills{
    padding-top: 40px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.about .skills .box{
    width: 18%;
    height: 150px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 20px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.about .skills .box img{
    height: 80px;
    width: 100%;
    object-fit: contain;
}

.git{
    height: 10px;
    width: 100%;
    object-fit: contain;
}


.projects{
    display: flex;
    justify-content: center;
    align-items: stretch;
    padding: 60px 120px;
    padding-top: 0px;
    gap: 60px;
    width: 100%;
    flex-wrap: wrap;
}

.head{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding-top: 60px;
}

.head h2{
    color: #AD49E1;
    font-size: 30px;
    text-align: center;
}


.head .line{
    width: 100px;
}
.box{
    width: 47%;
    align-items: stretch;
    justify-content: center;
    gap: 20px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    border-radius: 10px;
    border-bottom: 10px solid #AD49E1;
}

.box img{
    width: 100%;
    border-radius: 10px;
}

.box-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 20px;
}

.box h3{
    color: #AD49E1;
    font-size: 16px;
    text-align: center;

}


.box p{
    color: #AD49E1;
    font-size: 14px;
    text-align: center;
    font-weight: 500;
}

.box button{
    font-size: 16px;
    color: #AD49E1;
    background-color: transparent;
    border: none;
    font-weight: bold;
    cursor: pointer;
    /* border-bottom: 2px solid #AD49E1; */
    padding-bottom: 10px;
}


.projectsContainer{
    display: flex;
    justify-content: center;
    align-items: stretch;
    padding: 60px 120px;
    gap: 60px;
    width: 100%;
    flex-wrap: wrap;
}


.project{
    width: 100%;
    display: flex;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    align-items: stretch;
    border-radius: 20px;
}


.project-content{
    background-color: #AD49E1;
    width: 30%;
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    flex-direction: column;
    color: #ffffff;
    border-radius: 20px;
    text-align: center;
}

.project-content img{
    width: 100px;
}

.project-content button{
    padding: 15px 20px;
    background-color: #ffffff;
    border-radius: 5px;
    color: #AD49E1;
    border: none;
    font-weight: bold;
    border: 2px solid #AD49E1;
    border-radius: 50px;
    transition: all 0.5s ease-in-out;
    cursor: pointer;
}



.projectImage{
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.projectImage img{
    width: 100%;
    border-radius: 20px;
}









.resumebox{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    border-radius: 10px;
    border-bottom: 10px solid #AD49E1;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 40px 40px;
}

.resumebox img{
    width: 200px;
}


.resumebox h3{
    color: #AD49E1;
    font-size: 16px;
    text-align: center;

}

.resumebox p{
    color: #AD49E1;
    font-size: 14px;
    text-align: center;
    font-weight: 500;
}

.resumebox button{
    font-size: 16px;
    color: #AD49E1;
    background-color: transparent;
    border: none;
    font-weight: bold;
    cursor: pointer;
    border-bottom: 2px solid #AD49E1;
    padding-bottom: 10px;
}


.contact-container{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #AD49E1;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 60px 120px;
}

.contactbox1{
    width: 50%;
    display: flex;
    align-items: start;
    justify-content: start;
    flex-direction: column;
}

.contactbox1 img{
    width: 70%;
}

.contactbox2{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.contactbox2 h2{
    color: #ffffff;
    font-size: 30px;
    text-align: center;
}

.contactbox2 img{
    width: 100px;
    padding-bottom: 20px;
}

.contactbox2 .form{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.form input, textarea{
    width: 100%;
    padding: 20px;
    outline: none;
    border: 1px solid #AD49E1;
    border-radius: 10px;
    color: #AD49E1;
    font-weight: bold;
    font-size: 14px;
}

.resumeform{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 20px;
}

.resumeform input, textarea{
    width: 100%;
    padding: 20px;
    outline: none;
    border: 1px solid #AD49E1;
    border-radius: 10px;
    color: #AD49E1;
    font-weight: bold;
    font-size: 14px;
}

.resumeform button{
    font-size: 16px;
    width: 100%;
    color: #ffffff;
    background-color: #AD49E1;
    border: none;
    font-weight: bold;
    cursor: pointer;
    border-bottom: 2px solid #AD49E1;
    padding:10px;
    border-radius: 10px;
}
.blogapp{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #AD49E1;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 60px 120px;
    flex-direction: column;
}

.blogapp h2{
    color: #ffffff;
    font-size: 30px;
    text-align: center;
}

.blogapp .line{
    width: 100px;
    padding-top: 20px;
}


.blogapp p{
    font-size: 15px;
    font-weight: 500;
    color: #ffffff;
    font-weight: 400;
    text-align: center;
    padding-top: 20px;
    width: 70%;
}

.blogGallery{
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 60px;
    gap: 40px;
    color: #ffffff;
    font-weight: 500;

}

.blogGallery img{
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}




@media (max-width: 1200px) {
    .home-container{
        padding: 30px 60px;
    }

    .home-container .homebox1 h1{
        font-size: 35px;
    }
    .about{
        padding: 30px 60px;
    }
    .projects{
        padding: 30px 60px;
    }

    .projectsContainer{
        padding: 30px 60px;
    }
    .contact-container{
        padding: 30px 60px;
    }
    .box{
        width: 45%;
    }
    .about .skills .box{
        width: 30%;
    }
}


@media (max-width: 950px) {
    .project{
        flex-direction: column;
    }
    .project-content{
        width: 100%;
    }
    .projectImage{
        width: 100%;
    }
}

@media (max-width: 768px) {
    .home-container{
        flex-direction: column;
        padding: 30px;
        align-items: center;
        justify-content: center;
    }
    .home-container .homebox1{
        width: 100%;
        justify-content: center;
        align-items: center;
        gap: 20px;
        padding-top: 20px;
    }

    .home-container .homebox1 h2,h1,p{
        text-align: center;
    }


    .home-container .homebox1 h1{
        font-size: 30px;
    }


    .home-container .homebox2{
        width: 100%;
        justify-content: center;
        padding: 0;
    }

    .home-container .homebox2 img{
        width: 80%;
    }
    .home-container .homebox3{
        padding: 40px 0;
        width: 100%;
    }

    .home-container .homebox3 .socials{
        flex-direction: row;
    }

    .about{
        padding: 30px;
    }

    .about p{
        width: 90%;
    }

    .about .skills .box{
        padding: 10px;
        width: 25%;
        height: 100px;
    }

    .about .skills .box img{
        height: 100%;
    }
    .git{
        height: 30px;
    }

    .projects{
        padding: 60px 30px;
        gap: 60px;
    }

    .box{
        width: 100%;
    }

    .contact-container{
        padding: 60px 30px;
    }

    .blogapp{
        padding: 40px 20px;
    }
    .resumebox{
        width: 100%;
    }
}
@media (max-width: 480px) {
    .home-container{
        flex-direction: column;
        padding: 30px;
        align-items: center;
        justify-content: center;
    }
    .home-container .homebox1{
        width: 100%;
        justify-content: center;
        align-items: center;
        gap: 20px;
        padding-top: 20px;
    }

    .home-container .homebox1 h2,h1,p{
        text-align: center;
    }


    .home-container .homebox1 h1{
        font-size: 30px;
    }


    .home-container .homebox2{
        width: 100%;
        justify-content: center;
        padding: 0;
    }

    .home-container .homebox2 img{
        width: 100%;
    }
    .home-container .homebox3{
        padding: 40px 0;
        width: 100%;
    }

    .home-container .homebox3 .socials{
        flex-direction: row;
    }

    .about{
        padding: 30px;
    }

    .about p{
        width: 90%;
    }

    .about .skills .box{
        padding: 10px;
        width: 25%;
        height: 100px;
    }

    .about .skills .box img{
        height: 100%;
    }
    .git{
        height: 30px;
    }

    .projects{
        padding: 60px 30px;
        gap: 60px;
    }

    .box{
        width: 100%;
    }

    .contact-container{
        padding: 60px 30px;
    }

    .projectsContainer{
        padding: 40px 20px;
    }
}