.header {
    width: 100vw;
    background-color: #AD49E1;
    display: flex;
    justify-content: space-between;
    padding: 20px 120px;
    height: 90px;
    /* box-shadow: rgba(0, 0, 0, 0.80) 0px 5px 15px; */
    border-bottom: 1px solid #ffffff;
}

.left-navbar {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}


.left-navbar ul {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
}

.left-navbar ul li {
    list-style-type: none;
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;

}

.left-navbar ul li a {
    text-decoration: none;
    color: #ffffff;
    padding-bottom: 5px;
    border-bottom: 2px solid transparent;
}

.left-navbar ul li a:hover {
    border-bottom: 3px solid #ffffff;
}

.center-navbar h1 {
    color: #ffffff;
}

.center-navbar .logo {
    width: 100px;
    border-radius: 50%;
}

.center-navbar img{
    width: 200px;
}

.center-navbar .line {
    width: 100px;
    padding-top: 30px;
}

.center-navbar {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 25px;
}



.right-navbar {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 20px;
}

.right-navbar button {
    padding: 15px 20px;
    background-color: #ffffff;
    border-radius: 5px;
    color: #AD49E1;
    border: none;
    font-weight: bold;
    border: 2px solid #AD49E1;
    border-radius: 50px;
    transition: all 0.5s ease-in-out;
    cursor: pointer;
}

.right-navbar button:hover {
    transform: scale(1.05);
}

.right-navbar button a {
    text-decoration: none;
    color: #AD49E1;
}



.hamburger {
    display: none;
    cursor: pointer;
    font-size: 25px;
    color: #ffffff;
}

.close {
    display: none;
}

.open {
    display: flex;
}

@media (max-width: 1200px) {
    .header {
        padding: 20px 60px;
    }

    .center-navbar {
        width: 30%;
    }

    .left-navbar {
        width: 70%;
        justify-content: center;
    }

    .right-navbar {
        display: none;
    }

}


@media (max-width: 900px) {

    .header {
        flex-direction: column;
        gap: 0px;
        height: 130px;

    }

    .center-navbar {
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    .left-navbar {
        width: 100%;
    }
}


@media (max-width: 768px) {

    .header{
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 80px;
        padding: 20px;
    }

    .center-navbar {
        width: 80%;
        justify-content: start;
    }

    .center-navbar h1 {
        font-size: 20px;
    }

    .left-navbar {
        display: none;
    }

    .right-navbar {
        display: none;
    }

    .mobileMenu {
        height: 100%;
        flex-direction: column;
        align-items: center;
        background-color: #AD49E1;
        position: fixed;
        top: 0;
        left: 0;
        width: 60vw;
        gap: 20px;
        list-style: none;
        padding: 40px 20px;
        z-index: 99999;
    }

    .mobileMenu button {
        background-color: #ffffff;
        color: #AD49E1;
        padding: 10px 20px;
        border-radius: 10px;
        border-color: #ffffff;
    }

    .mobileMenu button a {
        text-decoration: none;
        color: #AD49E1;
        font-weight: bold;
    }


    .mobileMenu li {
        list-style-type: none;
    }

    .mobileMenu li a {
        text-decoration: none;
        color: #ffffff;
    }

    .hamburger {
        display: block;
    }

    .header {
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
}

@media (max-width: 480px) {
    .center-navbar {
        width: 100%;
    }

    .center-navbar h1 {
        font-size: 20px;
    }

    .left-navbar {
        display: none;
    }

    .right-navbar {
        display: none;
    }

    .mobileMenu {
        height: 100%;
        flex-direction: column;
        align-items: center;
        background-color: #AD49E1;
        position: fixed;
        top: 0;
        left: 0;
        width: 60vw;
        gap: 20px;
        list-style: none;
        padding: 40px 20px;
        z-index: 999999;
    }

    .mobileMenu button {
        background-color: #ffffff;
        color: #AD49E1;
        padding: 10px 20px;
        border-radius: 10px;
        border-color: #ffffff;
    }

    .mobileMenu button a {
        text-decoration: none;
        color: #AD49E1;
        font-weight: bold;
    }


    .mobileMenu li {
        list-style-type: none;
    }

    .mobileMenu li a {
        text-decoration: none;
        color: #ffffff;
    }

    .hamburger {
        display: block;
    }

    .header {
        align-items: center;
        justify-content: space-between;
    }
}